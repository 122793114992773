import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue";
var _hoisted_1 = {
  class: "nav"
};
var _hoisted_2 = {
  class: "nav-top"
};
var _hoisted_3 = {
  class: "nav-top-tabs-img"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "nav-top-tabs-img"
};
var _hoisted_6 = ["src"];
var _hoisted_7 = {
  class: "nav-top-tabs-img"
};
var _hoisted_8 = ["src"];
var _hoisted_9 = {
  class: "nav-top-tabs-img"
};
var _hoisted_10 = ["src"];
var _hoisted_11 = {
  class: "nav-avatar"
};
var _hoisted_12 = ["src"];
import { ref } from "vue";
import { getStore, removeStore } from "@/util/store";
import { useRouter } from "vue-router";
import WujieVue from "wujie-vue3";
export default {
  __name: 'navLeft',
  setup: function setup(__props) {
    var bus = WujieVue.bus;
    var userInfo = getStore({
      name: "userInfo"
    }); // 获取userInfo

    var accountType = ref(userInfo.accountType);
    var homeImg = require("@/assets/home.svg");

    // 判断是否为管理员
    var isAdmin = function isAdmin() {
      return userInfo.memberType.some(function (role) {
        return role === "teacher";
      });
    };
    var mouseenter = function mouseenter(e, url) {
      e.target.className.indexOf("active") === -1 && (e.target.firstChild.src = require("@/assets/nav/".concat(url, "-active.svg")));
    };
    var mouseleave = function mouseleave(e, url) {
      e.target.className.indexOf("active") === -1 && (e.target.firstChild.src = require("@/assets/nav/".concat(url, ".svg")));
    };
    var router = useRouter();
    var handleGoHome = function handleGoHome() {
      //判断当前是否存在JSAndroid方法，如果出现错误， 属于pc端
      if (window.JSAndroid) {
        console.log("安卓端");
        window.JSAndroid.homeRefresh();
        window.JSAndroid.activityFinish();
      } else {
        bus.$emit("backHome");
        router.push("/home");
      }
      // 删除审核工作台筛选条件
      if (getStore({
        name: "sourceFilterData"
      })) {
        removeStore({
          name: "sourceFilterData"
        });
      }

      // try {
      //   $hxyAppMethod('activityFinish')
      // } catch (error) {
      //   router.push('/home')
      // }
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, function () {
        return [_createElementVNode("div", _hoisted_2, [_cache[16] || (_cache[16] = _createElementVNode("div", {
          class: "nav-top-logo"
        }, null, -1)), _createElementVNode("div", {
          class: _normalizeClass(["nav-top-tabs", {
            active: _ctx.$route.name === 'accountInfo'
          }]),
          onClick: _cache[0] || (_cache[0] = function ($event) {
            return _ctx.$router.push('/accountInfo');
          }),
          onMouseenter: _cache[1] || (_cache[1] = function (e) {
            return mouseenter(e, 'accinfo');
          }),
          onMouseleave: _cache[2] || (_cache[2] = function (e) {
            return mouseleave(e, 'accinfo');
          })
        }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
          src: require("@/assets/nav/".concat(_ctx.$route.name === 'accountInfo' ? 'accinfo-active' : 'accinfo', ".svg"))
        }, null, 8, _hoisted_4)]), _cache[12] || (_cache[12] = _createElementVNode("div", null, "帐号信息", -1))], 34), accountType.value !== 0 ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["nav-top-tabs", {
            active: _ctx.$route.name === 'userInfo'
          }]),
          onClick: _cache[3] || (_cache[3] = function ($event) {
            return _ctx.$router.push('/userInfo');
          }),
          onMouseenter: _cache[4] || (_cache[4] = function (e) {
            return mouseenter(e, 'user');
          }),
          onMouseleave: _cache[5] || (_cache[5] = function (e) {
            return mouseleave(e, 'user');
          })
        }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
          src: require("@/assets/nav/".concat(_ctx.$route.name === 'userInfo' ? 'user-active' : 'user', ".svg")),
          class: "nav-top-tabs-img"
        }, null, 8, _hoisted_6)]), _cache[13] || (_cache[13] = _createElementVNode("div", null, "个人信息", -1))], 34)) : _createCommentVNode("", true), isAdmin() ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["nav-top-tabs", {
            active: _ctx.$route.name === 'spaceInfo'
          }]),
          onClick: _cache[6] || (_cache[6] = function ($event) {
            return _ctx.$router.push('/spaceInfo');
          }),
          onMouseenter: _cache[7] || (_cache[7] = function (e) {
            return mouseenter(e, 'space');
          }),
          onMouseleave: _cache[8] || (_cache[8] = function (e) {
            return mouseleave(e, 'space');
          })
        }, [_createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
          src: require("@/assets/nav/".concat(_ctx.$route.name === 'spaceInfo' ? 'space-active' : 'space', ".svg")),
          class: "nav-top-tabs-img"
        }, null, 8, _hoisted_8)]), _cache[14] || (_cache[14] = _createElementVNode("div", null, "个人主页", -1))], 34)) : _createCommentVNode("", true), _createElementVNode("div", {
          class: _normalizeClass(["nav-top-tabs", {
            active: _ctx.$route.name === 'theme'
          }]),
          onClick: _cache[9] || (_cache[9] = function ($event) {
            return _ctx.$router.push('/theme');
          }),
          onMouseenter: _cache[10] || (_cache[10] = function (e) {
            return mouseenter(e, 'theme');
          }),
          onMouseleave: _cache[11] || (_cache[11] = function (e) {
            return mouseleave(e, 'theme');
          })
        }, [_createElementVNode("div", _hoisted_9, [_createElementVNode("img", {
          src: require("@/assets/nav/".concat(_ctx.$route.name === 'theme' ? 'theme-active' : 'theme', ".svg")),
          class: "nav-top-tabs-img"
        }, null, 8, _hoisted_10)]), _cache[15] || (_cache[15] = _createElementVNode("div", null, "主题设置", -1))], 34)])];
      }), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", {
        class: "nav-avatar-home",
        onClick: handleGoHome
      }, [_createElementVNode("img", {
        src: _unref(homeImg)
      }, null, 8, _hoisted_12)]), _cache[17] || (_cache[17] = _createElementVNode("div", {
        class: "nav-avatar-text"
      }, "回首页", -1))])]);
    };
  }
};